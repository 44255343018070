<template>
   <van-nav-bar z-index="999999" safe-area-inset-top  @click-left="handleClick" fixed  placeholder title="个人简历生成系统">
     <template #left>
       <svg t="1706514247221" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1944" width="2rem" height="2rem"><path d="M170.666667 213.333333h682.666666v85.333334H170.666667V213.333333z m0 512h682.666666v85.333334H170.666667v-85.333334z m0-256h682.666666v85.333334H170.666667v-85.333334z" fill="#444444" p-id="1945"></path></svg>
     </template>
   </van-nav-bar>
   <SlideBar v-model:show-left="show"></SlideBar>
     <router-view/>
</template>
<script setup lang="ts">
import SlideBar from "@/components/SlideBar.vue";
import {nextTick, onMounted, ref} from "vue";

const show = ref(false)


const handleClick = () =>{
  show.value = !show.value
}


</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0
}

html, body {
  height: 100%;
}

#app {
  height: 100vh;
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #eff2f5;
}

.flex{
  display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
  display: -moz-box; /* Firefox 17- */
  display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
  display: -moz-flex; /* Firefox 18+ */
  display: -ms-flexbox; /* IE 10 */
  display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
}

.flex-col{
  display: flex;
  flex-direction: column;
}

.align-center{
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.justify-center{
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
</style>

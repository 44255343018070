<template>
  <div class="home">
    <van-form required="auto" @submit="onSubmit">
      <div style="height: 45px; display: flex; align-items: center; justify-content: center;">
        <van-tag size="large" style="height: 32px;box-sizing:border-box" type="primary">基本信息</van-tag>
      </div>
      <van-cell-group inset>
        <van-field
            v-model="form.username"
            :rules="[{ required: true, message: '请填写姓名' }]"
            label="姓名"
            name="username"
            placeholder="姓名"
        />
        <van-field
            v-model="form.sex"
            :rules="[{ required: true, message: '请选择性别' }]"
            label="性别"
            name="sex"
        >
          <template #input>
            <van-radio-group v-model="form.sex" direction="horizontal">
              <van-radio name="男">男</van-radio>
              <van-radio name="女">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
            v-model="form.age"
            :rules="[{ required: true, message: '请填写年龄' }]"
            label="年龄"
            name="age"
            placeholder="年龄"
            type="digit"
        />
        <van-field
            v-model="form.phone"
            :rules="[{ required: true, message: '请填写手机号' }]"
            label="手机号"
            maxlength="11"
            name="phone"
            placeholder="手机号"
            type="digit"
        />
        <van-field
            v-model="form.email"
            label="邮箱"
            name="email"
            placeholder="邮箱"
        />
        <van-field
            v-model="form.weChat"
            label="微信号"
            name="weChat"
            placeholder="请输入微信号"
        />
        <van-field label=" ">
          <template #input>
            <van-checkbox v-model="whetherItIsTheSameAsTheMobilePhoneNumber" shape="square">是否与手机号相同</van-checkbox>
          </template>
        </van-field>
        <van-field
            v-model="form.dateOfFirstEmployment"
            :rules="[{ required: true, message: '请选择工作时长' }]"
            label="工作时长"
            name="email"
        >
          <template #input>
            <div :style="{flex:1,color:form.dateOfFirstEmployment? '' : '#d1d1d4'}"
                 @click="showDateOfFirstEmployment = true">
              {{ form.dateOfFirstEmployment && form.dateOfFirstEmployment || '请选择工作时长' }}
            </div>
            <van-popup
                v-model:show="showDateOfFirstEmployment"
                position="bottom"
            >
              <van-picker
                  :columns="workYears"
                  title="工作时长"
                  @cancel="cancel"
                  @confirm="confirm($event,'dateOfFirstEmployment')"
              />
            </van-popup>
          </template>
        </van-field>
        <van-field
            v-model="form.expectedSalary"
            :rules="[{ required: true, message: '请选择期望薪资' }]"
            label="期望薪资"
            name="email"
        >
          <template #input>
            <div :style="{flex:1,color:form.expectedSalary? '' : '#d1d1d4'}"
                 @click="showExpectedSalary = true">
              {{ form.expectedSalary && form.expectedSalary || '请选择期望薪资' }}
            </div>
            <van-popup
                v-model:show="showExpectedSalary"
                position="bottom"
            >
              <van-picker
                  :columns="expectedSalaryList"
                  title="期望薪资"
                  @cancel="cancel"
                  @confirm="confirm($event,'expectedSalary')"
              />
            </van-popup>
          </template>
        </van-field>
      </van-cell-group>
      <div style="height: 45px; display: flex; align-items: center; justify-content: center;position:relative">
        <van-tag size="large" style="height: 32px;box-sizing:border-box" type="primary">资格证书</van-tag>
        <van-button icon="plus" plain size="small" style="position: absolute;right:16px"
                    type="primary" @click="addRows('qualifications')">添加
        </van-button>
      </div>
      <template v-for="(item,i) in form.qualifications">
        <van-cell-group inset :style="{
           marginTop: form.qualifications.length > 1 && i > 0 ? '15px' : ''
        }" >
          <van-cell v-if="form.qualifications.length > 1">
            <template #title>
              <div style="text-align: left;line-height: 2.2;height:100%"> {{
                  item.name ? item.name : `第${i + 1}条`
                }}
              </div>
            </template>
            <template #right-icon>
              <van-button icon="cross" plain size="small" type="primary" @click="form.qualifications.splice(i,1)">
                删除当前条
              </van-button>
            </template>
          </van-cell>
          <van-field
              v-model="item.name"
              label="证书名称"
              name="qualifications"
              placeholder="证书名称"
          />

        </van-cell-group>
      </template>

      <div style="height: 45px; display: flex; align-items: center; justify-content: center;position:relative">
        <van-tag size="large" style="height: 32px;box-sizing:border-box" type="primary">教育信息</van-tag>
        <van-button icon="plus" plain size="small" style="position: absolute;right:16px"
                    type="primary" @click="addRows('education')">添加
        </van-button>
      </div>
      <template v-for="(item,i) in form.education">
        <van-cell-group :style="{
           marginTop: form.education.length > 1 && i > 0 ? '15px' : ''
        }" inset>
          <van-cell v-if="form.education.length > 1">
            <template #title>
              <div style="text-align: left;line-height: 2.2;height:100%"> {{
                  item.school ? item.school : `第${i + 1}条`
                }}
              </div>
            </template>
            <template #right-icon>
              <van-button icon="cross" plain size="small" type="primary" @click="form.education.splice(i,1)">
                删除当前条
              </van-button>
            </template>
          </van-cell>
          <van-field
              v-model="item.school"
              :rules="[{ required: true, message: '请填写学校名称' }]"
              label="学校名称"
              name="school"
              placeholder="学校名称"
          />

          <van-field
              v-model="item.specialized"
              :rules="[{ required: true, message: '请填写专业' }]"
              label="专业"
              name="specialized"
              placeholder="专业"
          />
          <van-field
              v-model="item.degree"
              :rules="[{ required: true, message: '请选择学历' }]"
              label="学历"
              name="degree"
              placeholder="学历"
          >
            <template #input>
              <div :style="{flex:1,color:item.degree? '' : '#d1d1d4'}" @click="showDegree = true;index = i">
                {{ item.degree && item.degree || '请选择学历' }}
              </div>
              <van-popup
                  v-model:show="showDegree"
                  position="bottom"
              >
                <van-picker
                    :columns="degreeList"
                    title="学历"
                    @cancel="cancel"
                    @confirm="confirm($event,'degree')"
                />
              </van-popup>
            </template>
          </van-field>
          <van-field
              v-model="item.schoolTime"
              :rules="[{ required: true, message: '请选择在校时间' }]"
              label="在校时间"
              name="schoolTime"
              placeholder="在校时间"
          >
            <template #input>
              <div :style="{flex:1,color:item.schoolTime? '' : '#d1d1d4'}" @click="showSchoolTime = true;index = i">
                {{ item.schoolTime && item.schoolTime || '请选择在校时间' }}
              </div>
              <van-popup
                  v-model:show="showSchoolTime"
                  position="bottom"

              >
                <van-picker-group
                    :tabs="['开始时间', '结束时间']"
                    next-step-text="下一步"
                    title="在校时间"
                    @cancel="cancel"
                    @confirm="confirm($event,'schoolTime')"
                >
                  <van-date-picker
                      v-model="schoolTimeStart"
                      :columns-type="['year', 'month']"
                      :minDate="minDate"
                      title="请选择在校开始时间"
                  />
                  <van-date-picker
                      v-model="schoolTimeEnd"
                      :columns-type="['year', 'month']"
                      :minDate="minDate"
                      title="请选择在校结束时间"
                  />
                </van-picker-group>
              </van-popup>

            </template>
          </van-field>

        </van-cell-group>
      </template>
      <div style="height: 45px; display: flex; align-items: center; justify-content: center;position:relative">
        <van-tag size="large" style="height: 32px;box-sizing:border-box" type="primary">工作经历</van-tag>
        <van-button icon="plus" plain size="small" style="position: absolute;right:16px"
                    type="primary" @click="addRows('workExperience')">添加
        </van-button>
      </div>
      <template v-for="(item,i) in form.workExperience">
        <van-cell-group :style="{
           marginTop: form.workExperience.length > 1 && i > 0 ? '15px' : ''
        }" inset>
          <van-cell v-if="form.workExperience.length > 1">
            <template #title>
              <div style="text-align: left;line-height: 2.2;height:100%">
                {{ item.companyName ? item.companyName : `第${i + 1}条` }}
              </div>
            </template>
            <template #right-icon>
              <van-button icon="cross" plain size="small" type="primary" @click="form.workExperience.splice(i,1)">
                删除当前条
              </van-button>
            </template>
          </van-cell>
          <van-field
              v-model="item.companyName"
              :rules="[{ required: true, message: '请填写公司名称' }]"
              label="公司名称"
              name="school"
              placeholder="公司名称"
          />
          <van-field
              v-model="item.posts"
              :rules="[{ required: true, message: '请填写职位' }]"
              label="职位"
              name="specialized"
              placeholder="职位"
          />
          <van-field
              v-model="item.timeInOffice"
              :rules="[{ required: true, message: '请选择在职时间' }]"
              label="在职时间"
              name="timeInOffice"
              placeholder="在职时间"
          >
            <template #input>
              <div :style="{flex:1,color:item.timeInOffice? '' : '#d1d1d4'}"
                   @click="showTimeInOffice = true;index = i">
                {{ item.timeInOffice && item.timeInOffice || '请选择在职时间' }}
              </div>
              <van-popup
                  v-model:show="showTimeInOffice"
                  position="bottom"
              >
                <van-picker-group
                    :tabs="['开始日期', '结束日期']"
                    next-step-text="下一步"
                    title="在职时间"
                    @cancel="cancel"
                    @confirm="confirm($event,'timeInOffice')"
                >
                  <van-date-picker
                      v-model="timeInOfficeStart"
                      :columns-type="['year', 'month']"
                      :minDate="minDate"
                      title="请选择在职时间"
                  />
                  <van-date-picker
                      v-model="timeInOfficeEnd"
                      :columns-type="['year', 'month']"
                      :minDate="minDate"
                      title="请选择在职时间"
                  />
                </van-picker-group>
              </van-popup>

            </template>
          </van-field>

          <van-field
              v-model="item.jobDescription"
              :rules="[{ required: true, message: '请填写工作内容' }]"
              label="工作内容"
              name="degree"
              placeholder="工作内容"
              rows="5"
              type="textarea"
          />
        </van-cell-group>
      </template>
      <div style="height: 45px; display: flex; align-items: center; justify-content: center;">
        <van-tag size="large" style="height: 32px;box-sizing:border-box" type="primary">自我介绍</van-tag>
      </div>
      <van-cell-group inset>
        <van-field v-model="form.selfEvaluation" label="自我介绍" maxlength="200"
                   placeholder="自我介绍"
                   rows="5" show-word-limit type="textarea"></van-field>

      </van-cell-group>

      <div style="margin: 16px;">
        <van-button block native-type="submit" round type="primary">
          提交
        </van-button>
      </div>
      <div style="height:60px"></div>
    </van-form>
  </div>
</template>
<script lang="ts" setup>
import {onMounted, ref, watch} from "vue";
import dayjs from "dayjs";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

const router = useRouter()

const form = ref({
  username: '',
  sex: '',
  age: '',
  phone: '',
  weChat:'',
  email: '',
  dateOfFirstEmployment: '',
  expectedSalary:'',
  education: [
    {
      schoolTime: '',
      school: '',
      specialized: '',//专业
      //学历
      degree: '',
    },

  ],
  workExperience: [
    {//工作经历
      timeInOffice: '',
      companyName: '',
      posts: '',
      jobDescription: '',
    }
  ],
  selfEvaluation: '',
  qualifications:[{
    name:''
  }],
})


const workYears = ref([
  {text: '实习', value: '实习'},
  {text: '不足1年', value: '不足1年'},
  {text: '1年', value: '1年'},
  {text: '2年', value: '2年'},
  {text: '3年', value: '3年'},
  {text: '4年', value: '4年'},
  {text: '5年', value: '5年'},
  {text: '6年', value: '6年'},
  {text: '7年', value: '7年'},
  {text: '8年', value: '8年'},
  {text: '9年', value: '9年'},
  {text: '10年', value: '10年'},
  {text: '10年以上', value: '10年以上'},
])


const expectedSalaryList = ref([
  {text: '面议', value: '面议'},
  {text: '3k以下', value: '3k以下'},
  {text: '3-5k', value: '3-5k'},
  {text: '5-7k', value: '5-7k'},
  {text: '7-10k', value: '7-10k'},
  {text: '10-15k', value: '10-15k'},
  {text: '15k以上', value: '15k以上'},
])

onMounted(() => {
  if (store.getters.data) {
    form.value = store.getters.data
  }
})

const degreeList = ref([
  {text: '小学', value: '小学',},
  {text: '初中', value: '初中',},
  {text: '高中/职高/中专', value: '高中/职高/中专',},
  {text: '专科/大专', value: '专科/大专',},
  {text: '本科', value: '本科',},
  {text: '硕士研究生', value: '硕士研究生',},
  {text: '博士研究生', value: '博士研究生',},
])

const showSchoolTime = ref(false)
const showTimeInOffice = ref(false)
const showDegree = ref(false)
const showDateOfFirstEmployment = ref(false)
const showExpectedSalary = ref(false)

const minDate = ref(new Date('1990/01/01 00:00:00'))
const whetherItIsTheSameAsTheMobilePhoneNumber =ref(false)
watch(whetherItIsTheSameAsTheMobilePhoneNumber,(newVal)=>{
  if(newVal){
    form.value.weChat = form.value.phone
  }else{
    form.value.weChat = ''
  }
})

const schoolTimeEnd = ref([new Date().getFullYear(), new Date().getMonth() + 1])
const schoolTimeStart = ref([new Date().getFullYear(), new Date().getMonth() + 1])
const dateOfFirstEmployment = ref([new Date().getFullYear(), new Date().getMonth() + 1])
const timeInOfficeStart = ref([new Date().getFullYear(), new Date().getMonth() + 1])
const timeInOfficeEnd = ref([new Date().getFullYear(), new Date().getMonth() + 1])
const index = ref(0)

const confirm = ({selectedValues, selectedOptions}: any, from: any) => {
  if (from === 'timeInOffice') {
    form.value.workExperience[index.value].timeInOffice = `${dayjs(timeInOfficeStart.value.join('-')).format('YYYY-MM')}  -  ${dayjs(timeInOfficeEnd.value.join('-')).format('YYYY-MM')}`
  } else if (from === 'schoolTime') {
    form.value.education[index.value].schoolTime = `${dayjs(schoolTimeStart.value.join('-')).format('YYYY-MM')}  -  ${dayjs(schoolTimeEnd.value.join('-')).format('YYYY-MM')}`
  } else if (from === 'degree') {
    form.value.education[index.value].degree = selectedValues.join('')
  } else if (from === 'dateOfFirstEmployment') {
    form.value.dateOfFirstEmployment = selectedValues.join('-')
  } else if (from === 'expectedSalary') {
    form.value.expectedSalary = selectedValues.join('')
  }
  cancel()
}

const cancel = () => {
  showSchoolTime.value = false
  showTimeInOffice.value = false
  showDegree.value = false
  showDateOfFirstEmployment.value = false
  showExpectedSalary.value = false
}

const addRows = (from: string) => {
  if (from === 'workExperience') {
    form.value.workExperience.push({
      timeInOffice: '',
      companyName: '',
      posts: '',
      jobDescription: '',
    })
  } else if ( from === 'qualifications'){
    form.value.qualifications.push({
      name:''
    })
  } else {
    form.value.education.push({
      schoolTime: '',
      school: '',
      specialized: '',//专业
      //学历
      degree: '',
    })
  }
}

const store = useStore()

const onSubmit = (values: string) => {
  console.log('submit', values);
  store.dispatch('setData', form.value)
  router.push('/view')
};
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  overflow-y: auto;
}

</style>
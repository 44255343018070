import {createStore} from 'vuex'
const getStorage = () =>JSON.parse(localStorage.getItem('data') || 'null')

const setStorage = (data:any) =>{
    localStorage.setItem('data', JSON.stringify(data))
}

const initData = () => {
    return {
        username: '',
        sex: '',
        age: '',
        phone: '',
        weChat:'',
        email: '',
        dateOfFirstEmployment: '',
        expectedSalary:'',
        education: [
            {
                schoolTime: '',
                school: '',
                specialized: '',//专业
                //学历
                degree: '',
            },

        ],
        workExperience: [
            {//工作经历
                timeInOffice: '',
                companyName: '',
                posts: '',
                jobDescription: '',
            }
        ],
        selfEvaluation: '',
        qualifications:[{
            name:''
        }],
    }
}
export default createStore({
    state: {
        data: getStorage() || initData()
    },
    getters: {
        data: state => state.data
    },
    mutations: {
        SET_DATA(ctx, payload) {
            ctx.data = payload
        }
    },
    actions: {
        setData({commit}, payload) {
            commit('SET_DATA', payload)
            setStorage(payload)
        },
        resetData({commit}){
            commit('SET_DATA',initData())
            localStorage.clear()
            return Promise.resolve()
        }
    }
})

<template>

  <van-popup
      v-model:show="show"
      :style="{ width: '50%', height: '100%' }"
      position="left"
  >
    <div class="bg">

      <van-nav-bar safe-area-inset-top/>
      <van-sidebar v-model="active" @change="show= false">
        <van-sidebar-item title="首页" to="/"/>
        <van-sidebar-item title="重置简历" @click="reset"/>
        <van-sidebar-item title="我的简历" to="/view"/>
        <van-sidebar-item title="免责声明" to="/disclaimer"/>
      </van-sidebar>
    </div>
  </van-popup>
</template>

<script lang="ts" setup>

import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import {showDialog} from "vant";
import {useStore} from "vuex";

const props = defineProps({
  showLeft: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['update:showLeft'])

const router = useRouter()
const store = useStore()

const active = ref(0)

const reset = () =>{
  showDialog({
    message: '是否确认重置简历',
    showCancelButton:true
  }).then(() => {
    store.dispatch('resetData').then(res=>{
      router.replace('/')
      emits('update:showLeft', false)
    })
  }).catch(err=>{
  })

}



const show = computed({
  set(newVal: Boolean) {
    emits('update:showLeft', newVal)
  },
  get() {
    switch (router.currentRoute.value.path) {
      case '/':
        active.value = 0
        break
      case '/view':
        active.value = 2
        break
      case '/disclaimer':
        active.value = 3
        break
    }
    return props.showLeft || false
  }
})


</script>


<style lang="scss">

:root, :host {
  --van-sidebar-width: 100% !important;
}

.bg {
  height: 100%;
  background: var(--van-background);
}
</style>